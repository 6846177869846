import { Component, Input, OnInit } from "@angular/core";
import { ChernoffFace, Glyph } from "../../models/glyph";

@Component({
  selector: "g[fds-chernoff-face]",
  templateUrl: "./chernoff-face.component.html",
})
export class ChernoffFaceComponent implements OnInit {
  chernoffFace: ChernoffFace | undefined;
  @Input()
  glyph: Glyph | undefined;

  x: number = 10;
  y: number = 10;

  ngOnInit() {
    if (this.glyph) this.chernoffFace = this.glyph as ChernoffFace;
  }
}
