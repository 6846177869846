import { Component, Input, OnInit } from "@angular/core";
import { Glyph, TextGlyph } from "../../models/glyph";

@Component({
  selector: "g[fds-text-glyph]",
  templateUrl: "./text-glyph.component.html",
})
export class TextGlyphComponent implements OnInit {
  @Input()
  glyph: Glyph | undefined;
  textGlyph: TextGlyph | undefined;

  ngOnInit() {
    if (this.glyph) this.textGlyph = this.glyph as TextGlyph;
  }
}
