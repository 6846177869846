<div class="widget">
  <div class="p-3 flex flex-row justify-between text-xs">
    <div class="flex flex-row space-x-2">
      <div *ngIf="name" class="badge">
        <span>{{ name }}</span>
      </div>
      <div *ngIf="projectionType" class="badge-indigo">
        <svg
          fill="currentColor"
          height="15px"
          viewBox="0 0 20 20"
          width="15px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
            fill-rule="evenodd"
          />
        </svg>
        <span>{{ this.projectionType }}</span>
      </div>
    </div>
    <div class="flex flex-row space-x-2">
      <div
        (click)="onGlyphSelected(currentGlyphType)"
        *ngFor="let currentGlyphType of this.glyphTypes"
        [ngClass]="{
          'bg-indigo-200': glyphType === currentGlyphType,
          'hover:bg-indigo-100': glyphType !== currentGlyphType
        }"
        class="rounded px-2 py-1 text-indigo-700"
        style="cursor: pointer"
      >
        <label style="cursor: pointer"
          >{{ currentGlyphType }}
          <input
            name="glyph"
            style="display: none"
            type="radio"
            value="{{ currentGlyphType }}"
          />
        </label>
      </div>
    </div>
  </div>
  <div class="flex-1">
    <fds-projection
      [glyphType]="glyphType"
      [projectionType]="projectionType"
    ></fds-projection>
  </div>
</div>
