import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ChernoffFaceComponent } from "./glyph-designs/chernoff-face/chernoff-face.component";
import { LayoutComponent } from "./layout/layout.component";
import { ProjectionComponent } from "./projection/projection.component";
import { TextGlyphComponent } from "./glyph-designs/text-glyph/text-glyph.component";
import { WidgetComponent } from "./widget/widget.component";
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  providers: [],
  declarations: [
    AppComponent,
    LayoutComponent,
    ProjectionComponent,
    WidgetComponent,
    ChernoffFaceComponent,
    TextGlyphComponent,
  ],
  imports: [BrowserModule, AppRoutingModule,HttpClientModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
