import { Component, Input } from "@angular/core";
import { GlyphType } from "../models/glyphType";
import { ProjectionType } from "../models/projectionType";

@Component({
  selector: "fds-widget",
  styleUrls: ["widget.component.scss"],
  templateUrl: "widget.component.html",
})
export class WidgetComponent {
  glyphTypes: GlyphType[] = Object.values(GlyphType);
  @Input()
  name: string | undefined;
  @Input()
  projectionType: ProjectionType = ProjectionType.MDS;
  glyphType: GlyphType = GlyphType.CHERNOFF_FACE;

  onGlyphSelected(glyph: GlyphType): void {
    this.glyphType = glyph;
  }
}
