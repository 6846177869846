<div class="grid grid-cols-2" id="layout">
  <header class="col-span-2 px-10 py-10 grid">
    <div class="flex space-x-3">
      <button
        class="rounded bg-indigo-500 px-2.5 py-1.5 inline-flex items-center justify-between text-sm text-white font-bold hover:bg-indigo-700 space-x-1.5"
      >
        <svg
          fill="currentColor"
          height="20px"
          viewBox="0 0 20 20"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
            fill-rule="evenodd"
          />
        </svg>
        <span class="mx-2">Import</span>
      </button>
      <button
        class="rounded border-2 border-indigo-500 px-2.5 py-1.5 inline-flex items-center justify-between text-sm text-indigo-500 font-bold hover:text-indigo-700 space-x-1.5"
      >
        <svg
          fill="currentColor"
          height="20px"
          viewBox="0 0 20 20"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            fill-rule="evenodd"
          />
        </svg>
        <span class="mx-2">Export</span>
      </button>
    </div>
    <div><h1 class="font-bold text-3xl">Feature&nbsp;Data&nbsp;Spaces</h1></div>
    <div></div>
  </header>
  <div class="pb-10 pl-10 pr-5 flex items-center justify-center">
    <fds-widget name="Feature Space"></fds-widget>
  </div>
  <div class="pb-10 pl-5 pr-10 flex items-center justify-center">
    <fds-widget
      [projectionType]="dataSpaceProjection"
      name="Data Space"
    ></fds-widget>
  </div>
</div>
