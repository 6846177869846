import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BackendService } from "../backend.service";
import { Glyph } from "../models/glyph";
import { GlyphType } from "../models/glyphType";
import { ProjectionType } from "../models/projectionType";
import {ApiService} from "../api.service";

@Component({
  providers: [BackendService],
  selector: "fds-projection[glyphType][projectionType]",
  templateUrl: "./projection.component.html",
})
export class ProjectionComponent implements OnChanges, OnInit {
  glyphs: Glyph[] = [];
  GlyphType: typeof GlyphType = GlyphType;
  @Input()
  glyphType: GlyphType = GlyphType.CHERNOFF_FACE;
  @Input()
  projectionType: ProjectionType = ProjectionType.MDS;

  constructor(public backendService: BackendService,
              public dataService: ApiService) {}

  ngOnChanges() {
    this.getGlyphs();
  }

  ngOnInit() {
    this.dataService.getDatasets().subscribe(d => console.log('DATASETS', d));
    this.dataService.selectDataset().subscribe(d => {
      console.log(d);
      this.dataService.getFeatureValues().subscribe(d => console.log('DATA', d));
      this.dataService.getFeatureInformation().subscribe(d => console.log('FEATURE INFO', d));
    });

    // this.dataService.getProjectionData().subscribe(d => console.log('PROJ', d));
    this.getGlyphs();
  }



  private getGlyphs(): void {
    this.backendService
      .getGlyphs(this.glyphType)
      .subscribe((glyphs: Glyph[]) => (this.glyphs = glyphs));
  }
}
