import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { ChernoffFace, Glyph, TextGlyph } from "./models/glyph";
import { GlyphType } from "./models/glyphType";

@Injectable({
  providedIn: "root",
})
export class BackendService {

  getGlyphs(glyphType: GlyphType): Observable<Glyph[]> {
    switch (glyphType) {
      case GlyphType.CHERNOFF_FACE:
        return this.getChernoffFaces();
      case GlyphType.TEXT_GLYPH:
        return this.getTextGlyphs();
      default:
        return from([]);
    }
  }

  private getChernoffFaces(): Observable<ChernoffFace[]> {
    return from([
      [
        {
          radius: 5,
          x: 50,
          y: 50,
        },
      ],
    ]);
  }

  private getTextGlyphs(): Observable<TextGlyph[]> {
    return from([
      [
        {
          text: "Text",
          x: 50,
          y: 50,
        },
      ],
    ]);
  }
}
