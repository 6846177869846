import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpParamsOptions} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {retry, catchError, map} from 'rxjs/operators';
import {DatasetLabel} from "./dataservice/dataservice-interfaces";


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) {}

    API_URL: string = environment.apiUrl;
    API_DATASET_URI: string = this.API_URL + "/api/dataset/";
    API_PROJECTION_URI: string = this.API_URL + "/api/projection/";

    /**
     * Return the standard configuration of HTTP Options, mainly for making sure
     * that we retrieve a JSON object from the backend.
     */
    httpOptions(): {headers: HttpHeaders, params: HttpParams} {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            params: new HttpParams()//.set('f', 'slice-0-5').set("d", '1-2-3')
        };
    }

    /**
     * General HTTP GET handling for all similar requests
     *
     * @param url: API request URL.
     * @param httpOptions: headers and params.
     */
    httpGetRequest(url: string, httpOptions?: {headers?: HttpHeaders, params?: HttpParams}): Observable<any> {
        if (!httpOptions) {
            httpOptions = this.httpOptions();
        }
        return this.http.get<any>(`${url}`, httpOptions)
            .pipe(
                map(this.extractData),
                retry(1),
                catchError(this.handleError)
            );
    }

    /**
     * Provides a two-dimensional array containing all data values of all features which is ordered as the
     * feature list that can be retrieved by calling getFeatureInformation
     *
     * @param normalized: Allows to retrieve normalized data values, which is false by default-
     */
    getFeatureValues(normalized: boolean = false): Observable<string | number [][]> {
        let _httpOptions = this.httpOptions();
        _httpOptions.params = new HttpParams().set('f','all').set('d','all');
        let _URL = this.API_DATASET_URI + (normalized ? 'normalized/' : '') + 'feature/values';
        return this.httpGetRequest(_URL, _httpOptions);
        // return this.http.get<string | number [][]>(_URL, );
    }

    /**
     * Provides a list of all currently available datasets
     */
    getDatasets(): Observable<string[]> {
        return this.httpGetRequest(`${this.API_DATASET_URI}list`, this.httpOptions());
    }

    /**
     * REST Call to select a dataset in the backend
     * Url: [ROOT]/api/dataset/select/:dataset
     * @param dataset
     */
    selectDataset(dataset: string = "iris"): Observable<any> {
        let reqHeader = new HttpHeaders({ 'Content-Type': 'application/json','No-Auth':'True' });
        return this.httpGetRequest(`${this.API_DATASET_URI}select/${dataset}`, {headers: reqHeader});
    }

    /**
     * Provides the required two-dimensional positions for the MDS projection.
     * Can be extended for normalized values or depending on the dimensions.
     *
     * @param type: Choose your projection type from MDS, UMAP, TSNE, etc.
     */
    getProjectionData(type: string = "mds"): Observable<any> {
        let _httpOptions = this.httpOptions();
        _httpOptions.params = new HttpParams().set('f','all').set('d','all');
        return this.http.get<any>(`${this.API_PROJECTION_URI}${type}`, this.httpOptions())
            .pipe(
                map(this.extractData),
                retry(1),
                catchError(this.handleError)
            );
    }

    /**
     * Get feature information (e.g., column names)
     */
    getFeatureInformation(): Observable<any> {
        return this.httpGetRequest(`${this.API_DATASET_URI}feature/info`);
    }

    /**
     * Function to extract the data when the server return some
     *
     * @param res
     */
    private extractData(res: Response) {
        let body = res;
        return body || {};
    }

    /**
     * Error handling
     */
    handleError(error: HttpErrorResponse): any {
        let errorMessage = '';
        if (error.status == 200) {
            // All ok.
            console.log(error);
            return of(true);
        }
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
