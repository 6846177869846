import { Component } from '@angular/core';
import { ProjectionType } from "../models/projectionType";

@Component({
  selector: "fds-layout",
  styleUrls: ["layout.component.scss"],
  templateUrl: "layout.component.html",
})
export class LayoutComponent {
  dataSpaceProjection: ProjectionType = ProjectionType.UMAP;
}
